import React, { useState, useEffect } from "react";
import "jquery";
import $ from "jquery";
import bannerrightshape from "../../../Assets/img/shape/banner-right-shape.png";
import BannerServices from "../../../Services/BannerServices";
import styled, { keyframes } from "styled-components";
import { slideInLeft } from "react-animations";
const SlideLeft = styled.div`
  animation: 2s ${keyframes`${slideInLeft}}`};
`;

var BannerServicesObj = new BannerServices();
export const Banner = () => {
  const [SliderDetails, setSliderDetails] = useState([]);
  const [length, setLength] = useState(0);
  useEffect(() => {
    BannerServicesObj.Client_GetSliderList()
      .then((res) => {

        if (res.data != null && res.data.length > 0) {
          setSliderDetails([...res.data]);

          const Imagecount = res?.data.filter(e => e.imageUrl !== null).length;
          setLength(Imagecount);
          setTimeout(() => {
            $("#preloader").hide();
          }, 3000);

        }

      })
      .catch(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 1000);
      });
  }, []);

  return (
    <>
      {/* Start Banner
      ============================================= */}

      <div className=" banner-area inc-video top-pad-50">
        <div
          id="bootcarousel"
          className="carousel text-light slide carousel-fade animate_text"
          data-ride="carousel"
        >

          {/* Indicators for slides */}
          {length && length <= 3 ? <div className="carousel-indicator">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">

                  <ol className="carousel-indicators right">
                    <li
                      data-target="#bootcarousel"
                      data-slide-to={0}
                      className="active slider"
                    />
                    <li
                      data-target="#bootcarousel"
                      data-slide-to={1}
                      className="slider"
                    />
                    <li
                      data-target="#bootcarousel"
                      data-slide-to={2}
                      className="slider"
                    />
                  </ol>

                </div>
              </div>
            </div>
          </div> : <></>}


          {/* Wrapper for slides */}
          <div className="carousel-inner">
            {SliderDetails != null && SliderDetails.length > 0 ? (
              SliderDetails.map((v, i) => {
                return (
                  <div
                    className={
                      i == 0
                        ? "carousel-item bg-cover active"
                        : "carousel-item bg-cover"
                    }
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_API_IMAGEPATH + v.imageUrl
                        })`,
                    }}
                  >
                    <div className="box-table">
                      <div className="box-cell shadow dark">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-8">
                              <SlideLeft>
                                <div className="content">
                                  {/* <a href="https://www.youtube.com/watch?v=owhuBrGIOsE" class="popup-youtube light video-play-button relative">
                                            <i class="ti-control-play"></i> <span>Watch Video</span>
                                        </a> */}{" "}
                                  <h2>
                                    {v.title}
                                    <br /> <span>{v.description}</span>
                                  </h2>
                                  <a
                                    data-animation="animated zoomInUp"
                                    // className="btn btn-theme effect btn-md"
                                    className="btn btn-gradient-home effect btn-lg"
                                    href={v.sliderUrl}
                                    target={"_blank"}
                                  >
                                    Discover More
                                  </a>
                                </div>
                              </SlideLeft>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Shape */}

                      <div
                        className="shape-right-bottom"
                        data-animation="animated slideInUp"
                        style={{
                          backgroundImage: `url(${bannerrightshape})`,
                        }}
                      />

                      {/* Shape */}
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>

          {/* End Wrapper for slides */}

          {/* Previous and Next Arrows */}
          {length && length > 3 ? <>
            <a
              className="carousel-control-prev"
              href="#bootcarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#bootcarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </> : <></>}

          {/* End Previous and Next Arrows */}
        </div>
      </div>

      {/* End Banner */}
    </>
  );
};
