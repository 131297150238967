import React, { useEffect, useRef } from "react";

function BootrapModel({
  Id,
  modelSize,
  ModelTitle,
  Module,
  CloseID,
  closepopup,
  modalbodyClass = "",
  sessionKey = null
}) {
  const modalRef = useRef(null);
  const modalInstance = useRef(null);

  const handleCloseClick = () => {
    if (modalInstance.current) {
      modalInstance.current.hide();
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
    }
    $("body").css("overflow", "inherit");

    if (sessionKey) {
      sessionStorage.setItem(sessionKey, 'true');
    }

    if (closepopup) {
      closepopup();
    }
  };

  useEffect(() => {
    if (sessionKey && sessionStorage.getItem(sessionKey) === 'true') {
      return;
    }

    const modalElement = modalRef.current;
    if (modalElement) {
      modalInstance.current = new bootstrap.Modal(modalElement, {
        backdrop: 'static',
        keyboard: false
      });

      modalInstance.current.show();
      $("body").css("overflow", "hidden");

      return () => {
        if (modalInstance.current) {
          modalInstance.current.hide();
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
          $("body").css("overflow", "inherit");
        }
      };
    }
  }, [Id, sessionKey]);

  if (sessionKey && sessionStorage.getItem(sessionKey) === 'true') {
    return null;
  }

  return (
    <div>
      <div
        id={Id}
        className="modal custom-modal fade"
        role="dialog"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        ref={modalRef}
      >
        <div className={`modal-dialog modal-dialog-centered ${modelSize}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{ModelTitle}</h5>
              <button style={{ fontSize: "13px" }}
                type="button"
                className="btn-close close"
                aria-label="Close"
                id={CloseID}
                onClick={handleCloseClick}
              >
              </button>
            </div>
            <div className={`modal-body ${modalbodyClass}`} style={{ overflowY: 'visible' }}>
              {Module}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BootrapModel;