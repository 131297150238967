import Axios from "axios";

//QA
//Axios.defaults.baseURL = "http://vaanamrevampqaapi.vaanamtech.com/api/";

//Prod
// Axios.defaults.baseURL = "http://vaanamtechprodapi.vaanamtech.com/api/";

// dev
//Axios.defaults.baseURL = "http://localhost:47189/api/";

//AZURE QA
// Axios.defaults.baseURL = "https://vaanamnewqaapi.vaanamtech.com/api/";

// AZURE PROD
//Axios.defaults.baseURL = "https://vaanamtechapi.vaanamtech.com/api/";


//AZURE QA -New Update
// Axios.defaults.baseURL = "https://vaanamnewqaapi.azurewebsites.net/api/";

// AZURE Prod -New Update
Axios.defaults.baseURL = "https://vaanamtechapi.azurewebsites.net/api/";


Axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

export default class AxiosServices {
  post(url, data, Header = false) {
    return Axios.post(url, data, Header);
  }
  get(url, header = null) {
    return Axios.get(url, header);
  }
}
