import React from "react";
import ScrollToTop from "react-scroll-to-top";
import "../../../Assets/js/progress-bar.min.js";
import "../../../Assets/js/count-to.js";
import "../../../Assets/js/bootsnav.js";
import "../../../Assets/js/main.js";
import "../../../Assets/css/bootstrap.min.css";
import "../../../Assets/css/font-awesome.min.css";
import "../../../Assets/css/themify-icons.css";
import "../../../Assets/css/elegant-icons.css";
import "../../../Assets/css/flaticon-set.css";
import "../../../Assets/css/magnific-popup.css";
import "../../../Assets/css/owl.carousel.min.css";
import "../../../Assets/css/owl.theme.default.min.css";
import "../../../Assets/css/animate.css";
import "../../../Assets/css/bootsnav.css";
import "../../../Assets/css/style.css";
import "../../../Assets/css/responsive.css";
import bannerImage from "../../../Assets/img/Vaanam_Alert_Banner.jpeg";

import { Header } from "./Header";
import { Banner } from "./Banner";
import { About } from "./About";
import { Services } from "./Services.js";
import { Whychooseuse } from "./Whychooseuse";
import { Partnerarea } from "./Partnerarea";
import { Testimonialarea } from "./Testimonialarea";
import { Projectsarea } from "./Projectsarea";
import { Blog } from "./Blog";
import Contactarea from "./Contactarea";
import { Footer } from "./Footer";
import BootrapModel from "../../../BootrapModel/BootrapModel.js";

export const Home = () => {
  return (
    <>
      <div className="">
        <BootrapModel
          Id="imageModal"
          modelSize="modal-md"
          ModelTitle={
            <div className="d-flex align-items-center">
              <i className="fas fa-bell me-2 text-warning"></i> {/* Notification icon */}
              <span>Important Announcement</span> {/* More descriptive title */}
            </div>
          }
          Module={
            <img
              src={bannerImage}
              alt="Alert Banner"
              className="img-fluid w-100"
              style={{ display: 'block' }}
            />
          }
          modalbodyClass="p-0"
          sessionKey="alertPopupShown"
        />
        <div className="torch-red wrapper">
          <Header />
          <Banner />
          <About />
          <Services />
          <Whychooseuse />
          <Partnerarea />
          <Testimonialarea />
          <Projectsarea />
          <Blog />
          <Contactarea />
          <Footer />
          <ScrollToTop smooth color="#2b5690" />
        </div>
      </div>
    </>
  );
};